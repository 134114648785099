import {
  AccountCircleOutlined,
  FavoriteBorder,
  LocationOn,
  Menu,
  MenuOutlined,
  ShoppingCartOutlined,
} from "@mui/icons-material";
import { Box, Button, IconButton, Link } from "@mui/material";
import React, { useState } from "react";
import PrompkinsIcon from "../assets/images/logo_large_size.png";
import orderWithGoogleIcon from "../assets/images/order_with_google_lockup_horizontal.png";
import PompkinsGoogleIcon from "../assets/images/pompkins_google.png";
import PompkinsFoodIcon from "../assets/images/pompkins-food-nav.png";

import ModalDialogMenu from "./layoutComponents/ModalDialogMenu";
import { useAuthStore } from "../stores/useAuthStore";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLocationStore } from "../stores/useLocationStore";
import { useGuestLogin } from "../stores/useGuestLogin";
import GusetModalMenuMobile from "./layoutComponents/GusetModalMenuMobile";
import LanguageMenu from "./layoutComponents/LanguageMenu";
import ModalHomeDialogMenu from "./layoutComponents/ModalHomeDialogMenu";
import LocationNav from "./layoutComponents/LocationNav";
import { Helmet } from "react-helmet-async";
import foodFaviconIco from "../assets/images/favicon_package_pompkins_food/favicon-32x32.png";
import largeFoodPngIcon from "../assets/images/favicon_package_pompkins_food/PKFCircle-01.png";
import appleTouchFoodIcon from "../assets/images/favicon_package_pompkins_food/apple-touch-icon.png";
import imagePompkinOG from "../assets/images/coverFoodWeb-07.png";

function LayoutFoodMobile() {
  const version = new Date().toISOString().split("T")[0];
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const { isAuthenticated, setLocationFrom } = useAuthStore();
  const { currentAddress, openModal } = useLocationStore();
  const { isGuest } = useGuestLogin();
  const navigator = useNavigate();
  const [searchParams] = useSearchParams();
  const rwg_token = searchParams.get("rwg_token");

  const handleMenuClick = () => {
    if (isAuthenticated || isGuest) {
      setOpen(true);
    } else {
      setLocationFrom(location.pathname);
      navigator("/login/new");
    }
  };
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <meta property="og:image" content={`${imagePompkinOG}?v=${version}`} />
        <meta name="twitter:image" content={`${imagePompkinOG}?v=${version}`} />
        <link rel="image/png" href={`${foodFaviconIco}`} sizes="any" />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href={`${largeFoodPngIcon}`}
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${appleTouchFoodIcon}`}
        />
      </Helmet>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              flex: "0 0 auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {!location.pathname.includes("merchant-qr") &&
              !location.pathname.includes("menu/") &&
              location.pathname !== "/" && (
                <IconButton
                  color="inherit"
                  aria-label="menu"
                  component="span"
                  onClick={() => handleMenuClick()}
                >
                  <AccountCircleOutlined className="text-primary" />
                </IconButton>
              )}
            {location.pathname === "/" && (
              <IconButton
                color="inherit"
                aria-label="user"
                component="span"
                onClick={() => setOpen(true)}
              >
                <Menu className="text-black" />
              </IconButton>
            )}
          </Box>
          {!rwg_token ? (
            <Link
              href="/food"
              sx={{
                flex: "1 1 auto",
                display: "flex",
                // justifyContent: "center",
                maxWidth: "60%",
              }}
              onClick={() => window.location.reload()}
            >
              <img
                src={PompkinsFoodIcon}
                alt="logo"
                title="logo"
                loading="lazy"
                width={"150px"}
                height={"70px"}
                style={{
                  objectFit: "cover",
                }}
              />
            </Link>
          ) : (
            <Box
              sx={{
                flex: "1 1 auto",
                display: "flex",
                // justifyContent: "center",
                maxWidth: "60%",
              }}
            >
              <img
                src={PompkinsFoodIcon}
                alt="logo"
                title="logo"
                loading="lazy"
                width={"150px"}
                height={"70px"}
                style={{
                  objectFit: "cover",
                }}
              />
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              flex: "0 0 auto",
              justifyContent: "flex-end",
            }}
          >
            {(isAuthenticated || isGuest) && (
              <>
                {isAuthenticated && (
                  <>
                    <IconButton
                      color="inherit"
                      onClick={() => navigator("/favorite")}
                    >
                      <FavoriteBorder className="text-primary " />
                    </IconButton>
                  </>
                )}
                {!location.pathname.includes("merchant-qr") &&
                  !location.pathname.includes("menu/") && (
                    <IconButton
                      color="inherit"
                      onClick={() => navigator("/cart")}
                    >
                      <ShoppingCartOutlined className="text-primary " />
                    </IconButton>
                  )}
              </>
            )}
            <LanguageMenu />
          </Box>
        </Box>
        {!location.pathname.includes("queue") &&
          !location.pathname.includes("merchant-qr") &&
          !location.pathname.includes("order/bill") && (
            <Box sx={{ width: "100%", px: 1 }}>
              <LocationNav
                currentAddress={currentAddress}
                openModal={openModal}
                t={t}
              />
            </Box>
          )}
        {location.pathname === "/" && (
          <ModalHomeDialogMenu open={open} setOpen={setOpen} />
        )}
        {location.pathname !== "/" && isAuthenticated && (
          <ModalDialogMenu open={open} setOpen={setOpen} />
        )}
        {location.pathname !== "/" && isGuest && (
          <GusetModalMenuMobile open={open} setOpen={setOpen} />
        )}
      </Box>
    </>
  );
}

export default LayoutFoodMobile;
