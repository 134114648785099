import React, { useCallback, useEffect, useState, createRef } from "react";
import {
  Box,
  Typography,
  Grid,
  IconButton,
  Toolbar,
  AppBar,
  Badge,
  Button,
  Fab,
} from "@mui/material";
import DefaultProfile from "../../assets/images/pompkins_food_logo.png";
import DefaultBackground from "../../utils/defaultBackground";
import {
  getBMerchantById,
  getMenuByBMerchantId,
  getMerchantById,
  getCategoryByBMerchantId,
  getRatingByMerchantUUID,
  getServiceBMerchant,
} from "../../libs/merchantAPI/merchantAPI";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useMobile } from "../../stores/useMobile";
import {
  AccessTime,
  ShoppingBagOutlined,
  InfoOutlined,
} from "@mui/icons-material";
import MenuShopCard from "../../components/MenuShopCard";
import MenuShopCategoryTab from "../../components/MenuShopCategoryTab";
import LoadingScreen from "../../components/LoadingScreen";
import { isMerchantOpen } from "../../libs/isMerchantOpen";
import { useTranslation } from "react-i18next";
import StarIcon from "@mui/icons-material/Star";
import MerchantCloseModal from "../Merchant/MerchantCloseModal";
import MerchantNoMenu from "../Merchant/NoMenuModal";
import MoreInfoModal from "../Merchant/MoreInfoModal";
import ReviewModal from "../Merchant/ReviewModal";
import ModalNoPayment from "../../components/ModalNoPayment";
import ModalPriceError from "../../components/ModalPriceError";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import { set } from "react-hook-form";
import ShoppingBag from "../../assets/images/pompkins_food_logo.png";
import MenuSection from "../../components/MenuSection";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ModalExpireMerchant from "../../components/ModalExpireMerchant";

function MerchantQrPage() {
  const version = new Date().toISOString().split("T")[0];
  const { isMobile } = useMobile();
  const [isAddMenuOpen, setIsAddMenuOpen] = useState(false);
  const [merchantData, setMerchantData] = useState();
  const [bMerchantData, setBMerchantData] = useState();
  const [bMerchantMenu, setBMerchantMenu] = useState([]);
  const [bMerchantCategory, setBMerchantCategory] = useState([]);
  const [displayMenu, setDisplayMenu] = useState([]);
  const [isFetch, setIsFetch] = useState(false);
  const [cartList, setCartList] = React.useState([]);
  const [isMerchantOpenState, setIsMerchantOpenState] = useState(false);
  const [openCloseModal, setOpenCloseModal] = useState(true);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isMoreInfoOpen, setIsMoreInfoOpen] = useState(false);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [uuidProps, setUuidProps] = useState("");
  const [verified, setVerified] = useState(false);
  const [bMerchantService, setBMerchantService] = useState({});
  const [openNoPayment, setOpenNoPayment] = useState(true);
  const [openExpiredModal, setOpenExpiredModal] = useState(false);
  const [expired, setExpired] = useState(false);

  const { merchant_id, bmerchant_id, merchant_name } = useParams();
  const [rating, setRating] = useState(0);
  const { t } = useTranslation();
  const [openPriceError, setOpenPriceError] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/merchant-qr/${merchant_id}/${bmerchant_id}/${merchant_name}`,
      title: "MerchantQrPage",
    });
  }, []);

  useEffect(() => {
    const cartDataString = localStorage.getItem(`merchant_${bmerchant_id}`);
    let cartData = cartDataString ? JSON.parse(cartDataString) : {};
    if (cartData.cartList) {
      setTotalQuantity(cartData.totalQuantity);
      setTotalPrice(cartData.totalPrice);
      setCartList(cartData.cartList);
    }
  }, [bmerchant_id]);

  useEffect(() => {
    const data = {
      uuidProps: uuidProps,
      totalPrice: totalPrice,
      totalQuantity: totalQuantity,
      cartList: cartList,
      distance: 0,
    };

    if (cartList.length > 0) {
      localStorage.setItem(`merchant_${bmerchant_id}`, JSON.stringify(data));
    }
    if (cartList.length === 0) {
      localStorage.removeItem(`merchant_${bmerchant_id}`);
    }
  }, [bmerchant_id, cartList, totalPrice, totalQuantity, uuidProps]);

  const handleQuantityChange = (id, delta) => {
    const updatedCartList = [...cartList];
    const itemToUpdateIndex = updatedCartList.findIndex(
      (itm) => itm.menu.id === id
    );

    if (itemToUpdateIndex !== -1) {
      const itemToUpdate = updatedCartList[itemToUpdateIndex];
      const newQuantity = (itemToUpdate.quantity || 0) + delta;

      if (newQuantity === 0) {
        updatedCartList.splice(itemToUpdateIndex, 1);
      } else if (newQuantity > 0) {
        updatedCartList[itemToUpdateIndex].quantity = newQuantity;
      }

      setCartList(updatedCartList);
    }
  };

  const calculateTotals = useCallback(() => {
    let totalQuantity = 0;
    let totalPrice = 0;

    cartList.forEach((item) => {
      const quantity = parseInt(item.quantity, 10) || 0;
      const price = parseFloat(item.price, 10) || 0;

      totalQuantity += quantity;
      totalPrice += price;
    });

    setTotalQuantity(totalQuantity);
    setTotalPrice(totalPrice);
  }, [cartList]);

  useEffect(() => {
    calculateTotals();
  }, [calculateTotals]);

  const navigator = useNavigate();
  const [merchantLocation, setMerchantLocation] = useState({});
  const fetchMerchant = useCallback(() => {
    setIsLoading(true);
    getMerchantById(merchant_id)
      .then((res1) => {
        setMerchantData(res1.data);
        getBMerchantById(bmerchant_id)
          .then((res2) => {
            setBMerchantData(res2.data);
            setVerified(res2.data.verified);
            getServiceBMerchant(res2.data.uuid).then(({ data }) => {
              setBMerchantService(data);
            });
            const expire = res2.data.expire;
            if (expire) {
              const checkExpire = new Date(expire.expire_date) < new Date();
              if (checkExpire && expire.status === "Expired") {
                setOpenExpiredModal(true);
                setExpired(true);
              }
            }
            let timeTemp = isMerchantOpen(
              res2.data.open_time,
              res2.data.close_time
            );
            setIsMerchantOpenState(timeTemp);
            const location = {
              lat: parseFloat(res2.data.latitude),
              lng: parseFloat(res2.data.longitude),
            };
            getRatingByMerchantUUID(res2.data.uuid).then((res) => {
              setRating(res.data.average_rating);
            });
            setMerchantLocation(location);
            getMenuByBMerchantId(res2.data.uuid)
              .then((res3) => {
                setBMerchantMenu(res3.data);
                getCategoryByBMerchantId(res2.data.uuid)
                  .then((res4) => {
                    setBMerchantCategory(res4.data);
                    orderCategory(res3.data, res4.data);
                    setIsLoading(false);
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                  .finally(() => {
                    setIsFetch(true);
                  });
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const orderCategory = (menus, categories) => {
    let menuTemp = [];
    categories.forEach((category) => {
      let filteredMenu = menus.filter((menu) => {
        return menu.categories_id.includes(category.id);
      });
      menuTemp.push({ category: category, menu: filteredMenu });
    });
    setDisplayMenu(menuTemp);
  };

  useEffect(() => {
    if (!isFetch) {
      fetchMerchant();
    }
  }, [fetchMerchant, isFetch]);

  const [isLoading, setIsLoading] = React.useState(false);

  const scrollCategoryRefs = displayMenu.map(() => createRef());

  const scrollToCategory = (index) => {
    if (scrollCategoryRefs[index] && scrollCategoryRefs[index].current) {
      const yOffset = isMobile ? -130 : -120;
      const element = scrollCategoryRefs[index].current;
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      if (scrollTop > 300) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (cartList !== null && cartList.length > 0) {
      setIsAddMenuOpen(true);
    }
  }, [cartList]);

  useEffect(() => {
    if (bMerchantData) {
      setUuidProps(bMerchantData.uuid);
    }
  }, [bMerchantData]);

  const handleGoToCartPage = () => {
    const checkPrice = cartList.reduce(
      (acc, { price, quantity }) => acc + price * quantity,
      0
    );
    if (checkPrice < 1) {
      setOpenPriceError(true);
      return;
    }
    let sendProps = {
      order_detail: cartList,
      merchant_name: bMerchantData.name,
      merchant_uuid: bMerchantData.uuid,
      customer_uuid: "",
      distance: 0,
    };
    localStorage.setItem(
      `order_merchant_${bmerchant_id}`,
      JSON.stringify(sendProps)
    );
    navigator(`/merchant-qr/${merchant_id}/${bmerchant_id}/cartorder`);
  };

  if (!merchant_id || merchant_id === "undefined") {
    return <Navigate to="/food" />;
  }

  return (
    <>
      <Helmet>
        <title>{merchant_name} | POMPKINS Food</title>
        <meta
          name="description"
          content="สั่งเดลิเวอรีผ่าน POMPKINS Food  x Order with Google ได้แล้ววันนี้"
        />
        <link rel="canonical" href={`${window.location.href}`} />
        <meta property="og:url" content={`${window.location.href}`} />
        <meta
          property="og:title"
          content={`${merchant_name} | POMPKINS Food`}
        />
        <meta
          property="og:image"
          content={`${merchantData?.logo}?v=${version}` ?? DefaultProfile}
        />
        <meta
          name="twitter:image"
          content={`${merchantData?.logo}?v=${version}` ?? DefaultProfile}
        />
      </Helmet>
      {!isFetch && <LoadingScreen />}
      {/* {isFetch && !(bMerchantService.delivery_cash || verified) && (
        <ModalNoPayment
          open={openNoPayment}
          setOpenNoPayment={setOpenNoPayment}
          phone={bMerchantData.contract_number}
        />
      )} */}
      {openPriceError && (
        <ModalPriceError
          open={openPriceError}
          handleClose={() => setOpenPriceError(false)}
        />
      )}
      {isFetch && bMerchantMenu.length && !isMerchantOpenState ? (
        <MerchantCloseModal
          open={openCloseModal}
          setOpenClose={setOpenCloseModal}
        />
      ) : null}
      {isFetch && !bMerchantMenu.length ? <MerchantNoMenu open={true} /> : null}
      {isFetch && (
        <ModalExpireMerchant
          open={openExpiredModal}
          setOpen={setOpenExpiredModal}
          phone={bMerchantData.contract_number}
        />
      )}
      {isFetch && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mb: 10,
          }}
          width="100%"
        >
          <MoreInfoModal
            data={{
              name: merchantData.name,
              openTime: bMerchantData.open_time,
              closeTime: bMerchantData.close_time,
              location: merchantLocation,
              isMerchantOpen: isMerchantOpenState,
            }}
            open={isMoreInfoOpen}
            setOpen={setIsMoreInfoOpen}
          />
          <Box sx={{ width: "100%" }}>
            <ReviewModal
              uuid={bMerchantData.uuid}
              open={openReviewModal}
              handleClose={() => setOpenReviewModal(false)}
              isMobile={isMobile}
              rating={rating.toFixed(1)}
            />

            <Box
              sx={{
                position: "relative",
                backgroundColor: "white",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  backgroundImage: `url(${
                    merchantData.background ?? DefaultBackground
                  })`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  height: ["150px", "180px", "240px"],
                  alignItems: "end",
                  paddingX: 4,
                }}
              />
              <Box
                sx={{
                  p: 2,
                  bottom: 0,
                  display: "flex",
                  flexDirection: ["column", "column", "row"],
                  alignItems: ["start", "start", "center"],
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Box
                    alt="logo"
                    title="logo"
                    loading="lazy"
                    component="img"
                    src={merchantData.logo ?? DefaultProfile}
                    sx={{
                      minWidth: "110px",
                      width: ["110px", "110px", "150px"],
                      minHeight: "110px",
                      height: ["110px", "110px", "150px"],
                      borderRadius: "20px",
                      objectFit: merchantData.logo ? "cover" : "contain",
                    }}
                  />
                  <Box
                    sx={{
                      ml: 2,
                      py: [0, 0, 2],
                      display: "flex",
                      flexDirection: "column",
                      gap: 0.7,
                      backgroundColor: "white",
                      overflow: "hidden",
                    }}
                  >
                    <Typography variant="body2" color="textSecondary">
                      {merchantData.shop_type ?? "ร้านอาหารทั่วไป"}{" "}
                      {/* {bMerchantCategory &&
                        bMerchantCategory.map((itm) => `• ${itm.name} `)} */}
                    </Typography>
                    <Typography
                      className="font-bold"
                      sx={{
                        fontSize: ["18px", "20px", "24px"],
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                      }}
                    >
                      {merchantData.name}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 0.7,
                      }}
                    >
                      <AccessTime
                        sx={{
                          fontSize: ["16px", "18px", "20px"],
                          marginTop: "-2px",
                          color: isMerchantOpenState ? "green" : "red",
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: ["12px", "12px", "14px"],
                          color: isMerchantOpenState ? "green" : "red",
                        }}
                        variant="body2"
                      >
                        {isMerchantOpenState
                          ? t("merchant.merchantOpen")
                          : t("merchant.merchantClose")}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ fontSize: ["12px", "12px", "14px"] }}
                      >
                        {bMerchantData.open_time} - {bMerchantData.close_time}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: 0.7,
                      }}
                    >
                      <StarIcon
                        sx={{
                          color: "rgb(250, 175, 0)",
                          fontSize: "20px",
                          marginTop: "-2px",
                        }}
                      />
                      <Typography fontSize={"12px"} alignSelf={"center"}>
                        ({rating.toFixed(1)}/5)
                      </Typography>
                      <Button
                        onClick={() => setOpenReviewModal(true)}
                        variant="text"
                        sx={{
                          mr: 1,
                          p: 0,
                          minWidth: 0,
                          color: "#FF961B",
                          ":hover": {
                            backgroundColor: "#FFF7EB",
                          },
                        }}
                      >
                        {t("merchant.seeReivews")}
                      </Button>
                      <Button
                        onClick={() => setIsMoreInfoOpen(true)}
                        variant="text"
                        sx={{
                          p: 0,
                          minWidth: 0,
                          color: "#FF961B",
                          ":hover": {
                            backgroundColor: "#FFF7EB",
                          },
                        }}
                      >
                        <InfoOutlined
                          sx={{
                            marginTop: "-2px",
                            fontSize: "20px",
                            mr: "4px",
                          }}
                        />{" "}
                        {t("merchant.moreInfo")}
                      </Button>
                    </Box>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#FF961B",
                        fontSize: "12px",
                        paddingTop: "6px",
                      }}
                    >
                      <span className="text-red-500">*</span>{" "}
                      {t("actualDriving")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            {bMerchantCategory && (
              <MenuShopCategoryTab
                scrollToCategory={scrollToCategory}
                displayMenu={displayMenu}
                cartList={cartList}
                setCartList={setCartList}
                handleQuantityChange={handleQuantityChange}
                openNoPayment={openNoPayment}
              />
            )}
            {bMerchantCategory &&
              displayMenu
                .filter((item) => item.menu.length !== 0)
                .sort((a, b) => a.category.priority - b.category.priority)
                .map((object, index) => (
                  <MenuSection
                    key={index}
                    object={object}
                    index={index}
                    scrollCategoryRefs={scrollCategoryRefs}
                    cartList={cartList}
                    setCartList={setCartList}
                    handleQuantityChange={handleQuantityChange}
                    openNoPayment={openNoPayment}
                    openCloseModal={openCloseModal}
                    openExpireModal={openExpiredModal}
                    expired={expired}
                  />
                ))}
          </Box>
        </Box>
      )}
      {totalQuantity !== 0 && (
        <AppBar
          sx={{
            backgroundColor: "transparent",
            boxShadow: 0,
            top: "auto",
            bottom: 0,
            zIndex: 1000,
          }}
        >
          <Toolbar
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              mb: 2,
            }}
          >
            <IconButton
              onClick={() => {
                setIsAddMenuOpen(!isAddMenuOpen);
                handleGoToCartPage();
              }}
              sx={{
                backgroundColor: "#FF961B",
                width: "55px",
                height: "55px",
                color: "white",
                ":hover": {
                  backgroundColor: "#FF961B",
                  color: "white",
                },
              }}
            >
              <Badge
                sx={{
                  "& .MuiBadge-badge": {
                    minWidth: "15px",
                    width: "15px",
                    fontSize: "10px",
                    height: "15px",
                    color: "#FF961B",
                    backgroundColor: "white",
                  },
                }}
                badgeContent={totalQuantity}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <ShoppingBagOutlined />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
      )}
      {showScrollTop && (
        <Fab
          size="medium"
          variant="extended"
          onClick={scrollToTop}
          sx={{
            position: "fixed",
            bottom: 50,
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1000,
          }}
        >
          กลับไปด้านบน
          <KeyboardArrowUpIcon />
        </Fab>
      )}
    </>
  );
}

export default MerchantQrPage;
