import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import MenuShopCard from "./MenuShopCard";
import { useTheme } from "@emotion/react";

const MenuSection = ({
  object,
  index,
  scrollCategoryRefs,
  cartList,
  setCartList,
  handleQuantityChange,
  openNoPayment,
  openNoService,
  openCloseModal,
  openExpireModal,
  expired,
}) => {
  const theme = useTheme();
  const isXsOrSm = useMediaQuery(theme.breakpoints.down("md"));
  const isMd = useMediaQuery(theme.breakpoints.between("md", "lg"));

  return (
    <Box key={index} sx={{ px: 3, my: 4 }}>
      <Typography
        variant="h6"
        sx={{
          mt: 2,
          mb: 2,
          fontWeight: "bold",
        }}
        ref={scrollCategoryRefs[index]}
      >
        {object.category.name}
      </Typography>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            m: 0,
            gap: 2,
          }}
        >
          {object.menu.map((menus) => {
            if (
              Array.isArray(menus.options) &&
              Array.isArray(menus.options_id)
            ) {
              const optionsIdMap = new Map(
                menus.options_id.map((id, index) => [id, index])
              );
              menus.options = menus.options.sort(
                (a, b) => optionsIdMap.get(a.id) - optionsIdMap.get(b.id)
              );
            }
            return (
              <Box
                key={menus.id}
                sx={{
                  width: isXsOrSm
                    ? "100%"
                    : isMd
                    ? "calc(50% - 8px)"
                    : "calc(33.33% - 10.67px)",
                }}
              >
                <MenuShopCard
                  items={menus}
                  cartList={cartList}
                  setCartList={setCartList}
                  onIncrement={() => handleQuantityChange(menus.id, 1)}
                  onDecrement={() => handleQuantityChange(menus.id, -1)}
                  openNoPayment={openNoPayment}
                  openNoService={openNoService}
                  openCloseModal={openCloseModal}
                  expired={expired}
                />
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default MenuSection;
